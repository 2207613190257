import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TransactionService, UserService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { DatePipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Transaction, User } from '@/app/models';
import { PaginationMeta, ResponseWrapper } from '@/types';
import { TransactionType } from '@/enums';

@Component({
    selector: 'app-credits',
    standalone: true,
    imports: [RouterLink, NgIf, JsonPipe, NgClass, DatePipe, NgForOf],
    templateUrl: './credits.component.html',
    styleUrl: './credits.component.scss',
})
export class CreditsComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public user: User | null = null;
    public transactions: Transaction[] = [];
    public limit: number = 100;
    public activePage: number = 1;
    public pages: number[] = [];
    constructor(
        private userService: UserService,
        private transactionService: TransactionService,
        private route: ActivatedRoute,
    ) {
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            this.activePage = parseInt(paramMap.get('page') || '1');
            this.loadTransactions();
        });
    }

    loadTransactions() {
        this.isLoading = true;
        this.transactionService
            .getTransactions({
                page: this.activePage,
                limit: this.limit,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Transaction[]>) => {
                    const { data, meta } = response;
                    this.transactions = [...this.transactions, ...data];
                    this.isLoading = false;
                    const totalPages = meta.total_count / this.limit;
                    this.pages =
                        totalPages > 1
                            ? Array(totalPages)
                                  .fill(0)
                                  .map((x, i) => i + 1)
                            : [];
                },
                error: () => {
                    this.transactions = [];
                    this.isLoading = false;
                },
            });
    }

    ngOnInit() {
        this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
            this.user = user;
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly TransactionType = TransactionType;
}
