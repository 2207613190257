import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TrackingService, UserService } from '@/app/services';
import { Subject, takeUntil } from 'rxjs';
import { DatePipe, JsonPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Tracking } from '@/app/models';
import { ResponseWrapper } from '@/types';
import { TransactionType } from '@/enums';

@Component({
    selector: 'app-trackings',
    standalone: true,
    imports: [RouterLink, NgIf, JsonPipe, NgClass, DatePipe, NgForOf],
    templateUrl: './trackings.component.html',
    styleUrl: './trackings.component.scss',
})
export class TrackingsComponent implements OnDestroy {
    private ngUnsubscribe = new Subject<void>();
    public isLoading: boolean = false;
    public trackings: Tracking[] = [];
    public limit: number = 100;
    public activePage: number = 1;
    public pages: number[] = [];
    constructor(
        private trackingService: TrackingService,
        private route: ActivatedRoute,
    ) {
        this.route.queryParamMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paramMap) => {
            this.activePage = parseInt(paramMap.get('page') || '1');
            this.loadTrackings();
        });
    }

    loadTrackings() {
        this.isLoading = true;
        this.trackingService
            .getTrackings({
                page: this.activePage,
                limit: this.limit,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: ResponseWrapper<Tracking[]>) => {
                    const { data, meta } = response;
                    this.trackings = data;
                    this.isLoading = false;
                    const totalPages = meta.total_count / this.limit;
                    this.pages =
                        totalPages > 1
                            ? Array(totalPages)
                                  .fill(0)
                                  .map((x, i) => i + 1)
                            : [];
                },
                error: () => {
                    this.trackings = [];
                    this.isLoading = false;
                },
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected readonly TransactionType = TransactionType;
}
