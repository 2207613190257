<div class="alert alert-success mt-4">
    Available credits: <strong>{{ user?.wallet?.balance }}</strong>
</div>
<h3 class="mt-5 mb-4">Transactions</h3>
<ul class="list" *ngIf="transactions.length">
    <li *ngFor="let transaction of transactions" class="d-flex align-items-center transaction">
        <span class="d-block w-100">
            <strong class="d-flex justify-content-between">
                <span class="d-flex">{{ transaction.type }}</span>
                <span
                    class="d-flex"
                    [ngClass]="{
                        'text-success': transaction.type === TransactionType.DEPOSIT,
                        'text-danger': transaction.type === TransactionType.CHARGE
                    }"
                >
                    <span *ngIf="transaction.type === TransactionType.CHARGE">-</span>
                    <span *ngIf="transaction.type === TransactionType.DEPOSIT">+</span>
                    {{ transaction.amount }}
                </span>
            </strong>
            <small class="opacity-50">{{ transaction.date_created | date: 'medium' }}</small>
            <a [routerLink]="['/trackings', transaction.tracking.id]" *ngIf="transaction.tracking" class="m-0 mt-3 alert alert-light d-block text-decoration-none" role="alert">
                <small class="d-flex justify-content-between">
                    <span>TRACKING - {{ transaction.tracking.frames }} frames</span>
                    <span class="opacity-50">{{ transaction.tracking.id }}</span>
                </small>
                <strong>{{ transaction.tracking.file_path }}</strong>
            </a>
        </span>
    </li>
</ul>
<div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
    <div class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
