<h3 class="mt-2 mb-4">Trackings</h3>
<ul class="list" *ngIf="trackings.length">
    <li *ngFor="let tracking of trackings" class="d-flex align-items-center transaction">
        <a [routerLink]="['/trackings', tracking.id]" class="text-decoration-none text-black d-flex flex-grow-1">
            <span class="d-block w-100">
                <div class="d-flex">
                    <span class="mb-2">
                        <small class="d-block opacity-50">TRACKING - {{ tracking.frames }} frames</small>
                        <strong>{{ tracking.file_path }}</strong>
                    </span>
                    <span
                        class="badge ms-auto d-flex align-self-start"
                        [ngClass]="{
                            'text-bg-primary': tracking.status === 'TRACKING',
                            'text-bg-info': tracking.status === 'TRACKING_PENDING',
                            'text-bg-success': tracking.status === 'COMPLETED',
                            'text-bg-warning': tracking.status === 'PAYMENT_PENDING'
                        }"
                        >{{ tracking.status.replace('_', ' ') }}</span
                    >
                </div>
                <small class="opacity-50">{{ tracking.date_created | date: 'medium' }}</small>
            </span>
        </a>
    </li>
</ul>
<div *ngIf="!isLoading && pages.length > 1" class="d-flex justify-content-center mt-4">
    <ul class="pagination">
        <li *ngFor="let page of pages" class="page-item" aria-current="page">
            <a
                class="page-link"
                [routerLink]="['/trackings']"
                [queryParams]="{ page: page }"
                [ngClass]="{
                    active: activePage === page
                }"
                >{{ page }}</a
            >
        </li>
    </ul>
</div>
<div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
    <div class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
