<h3 class="mt-2 mb-5">Tracking - {{ tracking?.id }}</h3>
<div *ngIf="tracking">
    <dl class="row mt-4">
        <dt class="col-sm-3">File Path</dt>
        <dd class="col-sm-9">
            {{ tracking.file_path }}
        </dd>
        <dt class="col-sm-3">Status</dt>
        <dd class="col-sm-9">
            <span
                class="badge ms-auto d-inline-block align-self-start"
                [ngClass]="{
                    'text-bg-primary': tracking.status === 'TRACKING',
                    'text-bg-info': tracking.status === 'TRACKING_PENDING',
                    'text-bg-success': tracking.status === 'COMPLETED',
                    'text-bg-warning': tracking.status === 'PAYMENT_PENDING'
                }"
                >{{ tracking.status.replace('_', ' ') }}</span
            >
        </dd>
        <dt class="col-sm-3">Tracking Date</dt>
        <dd class="col-sm-9">
            {{ tracking.date_created | date: 'medium' }}
        </dd>

        <dt class="col-sm-3">Total Time</dt>
        <dd class="col-sm-9">
            {{ totalTime }}
        </dd>

        <dt class="col-sm-3">Tracked Time</dt>
        <dd class="col-sm-9">
            {{ trackedTime }}
        </dd>

        <dt class="col-sm-3">Tracked Frames</dt>
        <dd class="col-sm-9">
            {{ tracking.frames }}
        </dd>

        <dt class="col-sm-3">Frame Rate</dt>
        <dd class="col-sm-9">{{ tracking.frame_rate }}</dd>

        <dt class="col-sm-3">Receipt</dt>
        <dd class="col-sm-9">
            <button class="btn btn-sm btn-primary" (click)="downloadPDF()" [disabled]="pdfIsLoading">
                <span *ngIf="!pdfIsLoading">Download PDF</span>
                <span *ngIf="pdfIsLoading">Loading...</span>
            </button>
        </dd>
    </dl>
</div>

<div *ngIf="isLoading" class="p-5 my-5 d-flex justify-content-center">
    <div class="spinner-border">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
